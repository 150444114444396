"use strict";

exports.__esModule = true;
exports.default = useSwipeToDismiss;
var _react = require("react");
var _consts = require("../consts");
function useSwipeToDismiss(ref, onDismiss, distanceBeforeDismiss, direction) {
  const node = ref.current;
  const [removing, setRemoving] = (0, _react.useState)(false);
  const [pressedPosition, setPressedPosition] = (0, _react.useState)(0);
  const [positionLeft, setPositionLeft] = (0, _react.useState)(0);
  const [opacity, setOpacity] = (0, _react.useState)(1);
  const remove = (0, _react.useCallback)(() => {
    setTimeout(() => {
      onDismiss();
    }, _consts.SWIPE_DISMISS_DELAY);
  }, [onDismiss]);
  const onMouseUp = (0, _react.useCallback)(() => {
    if (!removing) {
      setPressedPosition(0);
      setPositionLeft(0);
      setOpacity(1);
    }
  }, [removing]);
  const onMouseMove = (0, _react.useCallback)(ev => {
    if (removing) return;
    if (pressedPosition && node) {
      let newPositionLeft = ev.screenX - pressedPosition;
      const directionValue = direction === "right" ? 1 : -1;
      if (direction === "right" && newPositionLeft >= node.offsetWidth * distanceBeforeDismiss / 100 || direction === "left" && newPositionLeft * directionValue >= node.offsetWidth * distanceBeforeDismiss / 100) {
        newPositionLeft += node.offsetWidth * directionValue;
        setRemoving(true);
        remove();
      } else if (direction === "right") {
        newPositionLeft = newPositionLeft < 0 ? 0 : newPositionLeft;
      } else {
        newPositionLeft = newPositionLeft > 0 ? 0 : newPositionLeft;
      }
      setPositionLeft(newPositionLeft);
      setOpacity((100 - newPositionLeft * directionValue * 100 / (node.offsetWidth * 2)) / 100);
    }
  }, [removing, pressedPosition, direction, distanceBeforeDismiss, node, remove]);
  const onMouseDown = (0, _react.useCallback)(ev => setPressedPosition(ev.screenX), [setPressedPosition]);
  (0, _react.useEffect)(() => {
    if (!node) setOpacity(1.1);
    // @ts-expect-error TODO
    else node.addEventListener("mousedown", onMouseDown);
    return () => {
      // @ts-expect-error TODO
      if (node) node.removeEventListener("mousedown", onMouseDown);
    };
  }, [node, onMouseDown, setOpacity]);
  (0, _react.useEffect)(() => {
    document.body?.addEventListener("mouseup", onMouseUp);
    document.body?.addEventListener("mousemove", onMouseMove);
    return () => {
      document.body?.removeEventListener("mouseup", onMouseUp);
      document.body?.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseUp, onMouseDown, onMouseMove]);
  return {
    swipeOffset: positionLeft,
    swipeOpacity: opacity
  };
}