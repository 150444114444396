"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.createToastPromise = exports.createToast = void 0;
var _reactHotToast = require("react-hot-toast");
var _ToastRoot = _interopRequireDefault(require("./ToastRoot"));
exports.ToastRoot = _ToastRoot.default;
const createToast = (content, options) => {
  (0, _reactHotToast.toast)(content, options);
};
exports.createToast = createToast;
const createToastPromise = (content, messages, options) => _reactHotToast.toast.promise(content, messages, options);
exports.createToastPromise = createToastPromise;